.single-column-layout {
  background-color: #e7ebee;

  .login-box {
    padding: 0;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-bottom-width: 5px;
    @include border-radius(5px);

    #login-box-header {
      height: 5px;

      & > div {
        height: 100%;
        width: 16.6667%;
        float: left;
      }

      .login-box-header-red {
        background: #e74c3c;
      }

      .login-box-header-green {
        background: #2ecc71;
      }

      .login-box-header-yellow {
        background: #f1c40f;
      }

      .login-box-header-purple {
        background: #9b59b6;
      }

      .login-box-header-blue {
        background: #3498db;
      }

      .login-box-header-gray {
        background: #95a5a6;
      }
    }

    h1 {
      background: none repeat scroll 0 0 #34495e;
      color: #fff;
      display: block;
      font-size: 2em;
      font-weight: 400;
      padding: 35px 0;
      text-align: center;
      text-transform: uppercase;

      & > img {
        display: block;
        height: 40px;
        margin: 0 auto;
      }

      & > span {
        display: block;
        font-size: 0.6em;
        font-weight: 300;
        text-transform: none;
      }
    }
  }
}

