#eyebrowNav {
  background: $light;
  color: $primary;
  padding: .25rem 0;
  font-size: 1.25rem;

  button.menu-toggle {
    .open-button {
      display: none;
    }
    .close-button {
      display: block;
    }
    &.collapsed {
      .open-button {
        display: block;
      }
      .close-button {
        display: none;
      }
    }
  }
  .logo {
    max-height: 2rem;
  }
}

#sidebarNav {
  padding: 0;
  z-index: 1000;
  background: $primary;
  min-height: 100vh;
  min-width: 25vw;
  @include media-breakpoint-down('sm') {
    min-width: 50vw;
  }


  a {
    color: $light;
    text-decoration: none;
  }

  .navbar-toggler {
  }

  .logo-container {
    img {
      max-width: 100%;
    }
  }

  ul.nav {
    list-style-type: none;

    li {
      a {
        padding: 1rem;
        -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
        transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
        border-left: 4px solid transparent;

        &:hover, &.active {
          border-left-color: #3498db;
        }

        //& > i {
        //  position: absolute;
        //  margin-top: 6px;
        //}

        & > span {

          &.label {
            margin-top: 5px;
            padding: 4px 0.6em;

            &.label-circle {
              margin-right: 5px;
            }
          }
        }
      }

      &.open {
        & > a {
          border-bottom-color: #252525;
          outline: none;
          text-decoration: none;
        }
      }
    }

    li {
      & > a {
        &.dropdown-toggle {
          & > .drop-icon {
            color: #868b98;
            margin-top: -6px;
            position: absolute;
            right: 25px;
            top: 50%;
            -webkit-transition: -webkit-transform 0.2s ease-in-out 0.1s;
            transition: transform 0.2s ease-in-out 0.1s;
          }
        }
      }

      .submenu {
        //display: none;
        background: #1a1d22;
        padding: 5px 0;
        margin: 0;
        list-style: none;


        //& > li {
        //  position: relative;
        //
        //  & > a {
        //    display: block;
        //    font-size: 0.875em;
        //    line-height: 38px;
        //    padding-left: 66px;
        //    color: #fff;
        //    outline: none;
        //    text-decoration: none;
        //    -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
        //    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
        //  }
        //
        //  &:first-of-type {
        //    & > a {
        //      border-top: 0;
        //    }
        //  }
        //
        //  &:last-of-type {
        //    & > a {
        //    }
        //  }
        //}
      }

    }

    & > .open {
      & > .submenu {
        .submenu {
          display: none;
        }

        & > li {
          & > a {
            position: relative;

            &.dropdown-toggle {
              & > .drop-icon {
                margin-top: -5px;
              }
            }
          }

          & > .submenu {
            background-color: #171717;

            & > li {
              a {
                &:hover {
                  color: #3498db;
                }
              }
            }

            a {
              border-bottom: 0 none;
              border-top: 0 none;
              padding-left: 85px;

              &:before {
                content: "\f111";
                display: inline;
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                margin-left: -10px;
                margin-top: 1px;
                position: absolute;
              }
            }
          }
        }

        & > .open {
          & > a {
            background: inherit;
            border-bottom-color: #171717;
            -webkit-box-shadow: 0 -1px 0 #171717 inset;
            box-shadow: 0 -1px 0 #171717 inset;

            padding: 2rem;


            &.dropdown-toggle {
              & > .drop-icon {
              }
            }
          }
        }
      }
    }
  }

  //&.collapse {
  //  visibility: visible;
  //
  //
  //  &.show {
  //    visibility: visible;
  //    display: block;
  //  }
  //
  //  &:not(.show) {
  //    display: block;
  //    max-width: 5rem;
  //    text-align: center;
  //
  //    li a span {
  //      display: none;
  //    }
  //
  //    @include media-breakpoint-down('sm') {
  //      width: 4rem;
  //      height: 4rem;
  //      min-height: 4rem;
  //
  //      ul {
  //        display: none;
  //      }
  //    }
  //
  //  }
  //
  //  .submenu {
  //    li a span {
  //      display: initial;
  //    }
  //  }
  //
  //}

  &.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;

    &.width {
      -webkit-transition-property: width, visibility;
      transition-property: width, visibility;
      width: 0;
      height: auto;
    }
  }

  &.collapse:not(.show) {
  }

}

#sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
#sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
  color: #fff;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#sidebar-nav .nav li .submenu > li > a:hover,
#sidebar-nav .nav li .submenu > li > a.active {
  text-decoration: none;
  color: #fff;
  background-color: #121418;
}


#sidebar-nav .nav > li > a:focus,
#sidebar-nav .nav .open > a,
#sidebar-nav .nav .open > a:focus {
  background: inherit;
}

#sidebar-nav .nav > li > a:hover,
#sidebar-nav .nav .open > a:hover {
  background: #1a1d22;
  color: #fff;
  outline: none;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus,
.navbar-toggle:hover,
.navbar-toggle:focus,
.mobile-search.active > .btn {
  background: #3498db;
  color: #fff;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus {
  background-color: #3498db;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
#sidebar-nav .nav-pills > li.active > a,
#sidebar-nav .nav-pills > li.active > a:hover,
#sidebar-nav .nav-pills > li.active > a:focus,
.nav-small #nav-col #sidebar-nav .nav-pills > li.open > a {
  background-color: #1a1d22;
  color: #fff;
  border-left-color: #3498db;
}
