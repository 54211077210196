html, body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
}
body {
	color: #344644;
	background: #e7ebee;
}
//h1, h2, h3, h4, h5, h6 {
//    font-family: 'Open Sans', 'Titillium Web', Geneva, sans-serif;
//    font-weight: 300;
//}
//h1 {
//	clear: both;
//    color: #3498db;/* 505458 */
//    margin: 0 0 20px 0;
//    padding-left: 14px;
//    font-size: 2em;
//}
//h2 {
//	clear: both;
//    font-size: 1.8em;
//    margin-bottom: 10px;
//    padding: 10px 0 10px 30px;
//}
//h3 {
//	border-bottom: 1px solid #C2C2C2;
//	padding-left: 5px;
//	margin-bottom: 15px;
//	margin-top: 30px;
//	font-size: 1.4em;
//}
//h3 > span {
//	border-bottom: 2px solid #C2C2C2;
//    display: inline-block;
//    padding: 0 5px 5px;
//}
//h4 {
//	font-size: 1.2em;
//}
//h5 {
//	font-size: 1.1em;
//}
//
//a {
//	color: #3498db;
//	outline: none !important;
//	text-decoration: none;
//}
//a:hover,
//a:focus {
//	color: #3498db;
//}
//@media (max-width: 420px) {
//	html,body {
//		font-size: 12px;
//	}
//}
//
//.container {
//	padding-left: 8px;
//	padding-right: 8px;
//}
//.row {
//    margin-left: -8px;
//    margin-right: -8px;
//}
//.col-xs-1,
//.col-sm-1,
//.col-md-1,
//.col-lg-1,
//.col-xs-2,
//.col-sm-2,
//.col-md-2,
//.col-lg-2,
//.col-xs-3,
//.col-sm-3,
//.col-md-3,
//.col-lg-3,
//.col-xs-4,
//.col-sm-4,
//.col-md-4,
//.col-lg-4,
//.col-xs-5,
//.col-sm-5,
//.col-md-5,
//.col-lg-5,
//.col-xs-6,
//.col-sm-6,
//.col-md-6,
//.col-lg-6,
//.col-xs-7,
//.col-sm-7,
//.col-md-7,
//.col-lg-7,
//.col-xs-8,
//.col-sm-8,
//.col-md-8,
//.col-lg-8,
//.col-xs-9,
//.col-sm-9,
//.col-md-9,
//.col-lg-9,
//.col-xs-10,
//.col-sm-10,
//.col-md-10,
//.col-lg-10,
//.col-xs-11,
//.col-sm-11,
//.col-md-11,
//.col-lg-11,
//.col-xs-12,
//.col-sm-12,
//.col-md-12,
//.col-lg-12 {
//	padding-left: 8px;
//	padding-right: 8px;
//}
//@media (max-width: 767px) {
//	.container {
//		padding-left: 5px;
//		padding-right: 5px;
//	}
//	.row {
//	    margin-left: -5px;
//	    margin-right: -5px;
//	}
//
//	.col-xs-1,
//	.col-sm-1,
//	.col-md-1,
//	.col-lg-1,
//	.col-xs-2,
//	.col-sm-2,
//	.col-md-2,
//	.col-lg-2,
//	.col-xs-3,
//	.col-sm-3,
//	.col-md-3,
//	.col-lg-3,
//	.col-xs-4,
//	.col-sm-4,
//	.col-md-4,
//	.col-lg-4,
//	.col-xs-5,
//	.col-sm-5,
//	.col-md-5,
//	.col-lg-5,
//	.col-xs-6,
//	.col-sm-6,
//	.col-md-6,
//	.col-lg-6,
//	.col-xs-7,
//	.col-sm-7,
//	.col-md-7,
//	.col-lg-7,
//	.col-xs-8,
//	.col-sm-8,
//	.col-md-8,
//	.col-lg-8,
//	.col-xs-9,
//	.col-sm-9,
//	.col-md-9,
//	.col-lg-9,
//	.col-xs-10,
//	.col-sm-10,
//	.col-md-10,
//	.col-lg-10,
//	.col-xs-11,
//	.col-sm-11,
//	.col-md-11,
//	.col-lg-11,
//	.col-xs-12,
//	.col-sm-12,
//	.col-md-12,
//	.col-lg-12 {
//		padding-left: 5px;
//		padding-right: 5px;
//	}
//}
//
///* new xxs layout for extra small devices */
//@media (max-width: 420px) {
//	h1 {
//		padding-left: 5px;
//		font-size: 1.8em;
//	}
//	h2 {
//		font-size: 1.5em;
//	}
//	.hidden-xxs {
//		display: none !important;
//	}
//	tr.hidden-xxs {
//		display: table-row !important;
//	}
//
//	th.hidden-xxs,
//	td.hidden-xxs {
//		display: table-cell !important;
//	}
//
//	.visible-xxs {
//		display: block !important;
//	}
//	tr.visible-xxs {
//		display: none !important;
//	}
//
//	th.visible-xxs,
//	td.visible-xxs {
//		display: none !important;
//	}
//	.breadcrumb {
//		padding-left: 6px;
//	}
//}


blockquote,
blockquote.pull-right {
	border-color: #3498db;
	padding: 10px 20px 10px 40px;
}
blockquote.pull-right {
	padding-left: 20px;
	padding-right: 40px;
}
blockquote p {
	font-style: italic;
}
blockquote:before {
	content: "\f10d";
	color: #C2C2C2;
    display: inline;
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    height: auto;
    line-height: normal;
    margin-left: -28px;
    margin-top: 0;
    position: absolute;
    width: auto;
}
blockquote.pull-right:before {
	margin-left: 0;
    right: 50px;
}

.container {
	margin: 0;
	max-width: 1920px;
	width: 100%;
}
.navbar-toggle {
    border: medium none;
    font-size: 1.4em;
    height: 50px;
    margin: 0;
    text-shadow: none;
    width: 50px;
    z-index: 100;
    border-radius: 0;
}
.navbar-toggle .icon-bar {
    background: none repeat scroll 0 0 white;
}
.nav > li {
	float: left;
}
.btn {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/* border: none; */
}
.navbar-nav {
	margin: 0 0 0 10px;
}
.navbar-nav > li > a {
    padding-bottom: 15px;
    padding-top: 15px;
    line-height: 24px;
}
.navbar-nav > li > .dropdown-menu {
	border-radius: 3px;
	min-width: 223px;
}
.dropdown-menu > li > a {
	color: #707070;
	font-size: 0.875em;
	line-height: 1.7;
}
.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus {
	background-color: #f6f6f6;
	color: #707070;
}
.dropdown-menu > li > a {
	padding-left: 35px;
	-webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
	transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}
.dropdown-menu > li > a > i {
	position: absolute;
	margin-left: -18px;
	margin-top: 4px;
}
.nav-pills > li {
	float: none;
}
.nav-pills > li > a {
	border-radius: 0;
}

/*
.navbar > .container .navbar-brand {
	background: #34495e;
	color: #fff;
    font-family: 'Titillium Web',Geneva,sans-serif;
    font-weight: 700;
    width: 190px;
    margin-left: -8px;
    padding: 10px 15px;
	z-index: 2;
}
#logo.navbar-brand > img {
    float: left;
    padding-right: 4px;
	width:100%;
}
*/

#logo{
}

#header-navbar {
    background: #fff;
    border: 0 none;
    border-radius: 0 0 0 0;
    margin: 0;
    min-height: 50px;
    color: #262626;
    -webkit-box-shadow: 0px 1px 3px 0 rgba(0,0,0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0 rgba(0,0,0, 0.1);
    box-shadow: 0px 1px 3px 0 rgba(0,0,0, 0.1);
    position: relative;
    z-index: 99;
}
#header-navbar .navbar-form .form-group {
	position: relative;
}
#header-navbar .navbar-form .form-control {
	background: #131313;
	color: #707070;
	height: 30px;
	line-height: 30px;
	margin-top: 2px;
	font-size: 0.75em;
}
#header-navbar .navbar-form .nav-search-icon {
	position: absolute;
	color: #707070;	position: absolute;
	margin-left: -18px;
	margin-top: 4px;
}
.nav-pills > li {
	float: none;
}
.nav-pills > li > a {
	border-radius: 0;
}

/*
.navbar > .container .navbar-brand {
	background: #34495e;
	color: #fff;
    font-family: 'Titillium Web',Geneva,sans-serif;
    font-weight: 700;
    width: 190px;
    margin-left: -8px;
    padding: 10px 15px;
	z-index: 2;
}
#logo.navbar-brand > img {
    float: left;
    padding-right: 4px;
	width:100%;
}
*/

#logo{
}

@media (max-width: 767px) {
	/*#logo.navbar-brand > img {
		height: 25px;
	}
	.navbar > .container .navbar-brand {
		padding-top: 12px;
		padding-top: 12.5px;
	}*/
}

.navbar-nav > li > .dropdown-menu.notifications-list {
	min-width: 320px;
	padding-bottom: 0;
	padding-top: 0;
	margin-top: 8px;
	color: #707070;
	margin-left: -265px;
}
.notifications-list .item-header {
	font-size: 0.875em;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
}
.notifications-list .item {
	border-top: 1px solid #f6f6f6;
    line-height: 1.4;
    padding-bottom: 0;
    padding-top: 0;
    clear: both;
}
.notifications-list .item a {
    clear: both;
    white-space: normal;
    padding-bottom: 8px;
    padding-top: 8px;
}
.notifications-list .item > a > i {
	color: #e74c3c;
}
.notifications-list .item a .content {
	
}
.notifications-list .item a .time {
	color: #3498db;
	margin-left: 10px;
	position: absolute;
    right: 13px;
    white-space: normal !important;
}
.notifications-list .item a .time i {
	margin-right: 3px;
}
.notifications-list .item-footer {
	background: #3498db;
    padding-bottom: 0;
    padding-top: 0;
}
.notifications-list .item-footer a {
	padding: 8px 20px;
    text-align: center;
    -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    color: #fff;
}
.notifications-list .item-footer a:hover,
.notifications-list .item-footer a:focus {
	background-color: #2980b9;
	color: #fff;
}
.notifications-list .pointer {
    height: 12px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 21px;
    top: -12px;
    width: 12px;
}
.notifications-list .pointer .pointer-inner {
	position: relative;
}
.notifications-list .pointer .arrow {
	border-color: transparent transparent #FFFFFF;
    border-style: solid;
    border-width: 6px;
    cursor: pointer;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1002;
}
.notifications-list .pointer .arrow-border {
    border-color: transparent transparent rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-width: 7px;
    cursor: pointer;
    left: -1px;
    position: absolute;
    top: -2px;
    z-index: 1001;
}
.messages-list .item.first-item {
	border-top: 0 !important;
}
.messages-list .item > a {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 25px;
	-webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
	transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}
.messages-list .item > a > img {
	position: absolute;
	margin-top: 10px;
}
.messages-list .item > a > .content {
	display: block;
    padding-left: 50px;
    padding-top: 5px;
}
.messages-list .item > a > .content .content-headline {
	color: #605F5F;
    display: block;
    font-weight: 600;
}
.messages-list .item > a > .content .content-text {
	display: block;
    line-height: 1.4;
}

#col-left {
    position: relative;
    color: #003940;
    height: 100%;
}
#col-left a {
	color: #e1e1e1;
}
#col-left a:hover,
#col-left .nav-active a.nav-link,
#col-left a.active {
	color: #fff;
}

#user-left-box {
	padding: 10px 15px 20px 15px;
}
#user-left-box img {
    float: left;
    width: 100%;
}
#user-left-box .user-box a#logo{
	display: inline-block;
}
#user-left-box .user-box {
	color: #fff;
	text-align: center;
	padding-left:0px;
	width: 100%;
}
#user-left-box .user-box > .name {
	display: block;
    font-size: .9em;
    font-weight: 600;
    line-height: 1.2;
}
#user-left-box .user-box > .status {
	display: block;
    font-size: 0.75em;
    padding-top: 3px;
}
#user-left-box .user-box > .status > i {
	color: #2ecc71;
	margin-right: 4px;
}
@media (min-width: 992px) {
	.nav-small #nav-col {
		width: 64px;
	}
	.nav-small #content-wrapper {
		margin-left: 64px;
	}
	.nav-small #nav-col #user-left-box {
		display: none;
	}
	.nav-small #nav-col #sidebar-nav .nav > li > a > span {
		display: none;
	}
	.nav-small #nav-col #sidebar-nav .nav li > a.dropdown-toggle > .drop-icon {
		display: none;
	}
	.nav-small #nav-col #sidebar-nav .nav .submenu > li > a.dropdown-toggle > .drop-icon {
		display: block;
	}
	.nav-small #nav-col #sidebar-nav .nav li .submenu {
		left: 64px;
	    position: absolute;
	    top: 0;
	    width: 210px;
	}
	.nav-small #nav-col #sidebar-nav .nav li .submenu > li > a {
		padding-left: 28px;
	}
	.nav-small #nav-col #sidebar-nav .nav > .open > .submenu > li > .submenu {
		left: auto;
	    position: relative;
	    top: auto;
	    width: 100%;
	}
	.nav-small #nav-col #sidebar-nav .nav > .open > .submenu > li > .submenu a {
		padding-left: 48px
	}
}
@media (max-width: 991px) {
	#nav-col {
		position: relative;
		width: auto;
	}
	.navbar-toggle {
		display: block;
	}
	
	#sidebar-nav.navbar-collapse {
		max-height: 360px;
	}
	#sidebar-nav .nav > li > a {    
	
	}
}
@media (max-width: 767px) {
	#logo {
		width: 150px;
		padding-left: 0;
		font-size: 1em;
		margin-left: 6px;
	}
	#logo img {
		height: 19px;
	}
}
@media (max-width: 400px) {
	#header-navbar .container {
		padding: 0;
	}
	#logo {
		width: auto;
		margin-left: 15px;
	}
	#logo span {
		display: none;
	}
	.profile-dropdown .dropdown-toggle {
	    padding-left: 5px !important;
	    padding-right: 5px !important;
	}
	#header-nav .nav {
		margin-left: 0;
	}
}


#content-wrapper {
	background: #e7ebee;
	height: 100%;
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	padding: 15px 15px 35px 15px;
	margin-left: 190px;
}
#content-wrapper > .row {
	opacity: 1;

	/* -webkit-transition: opacity 0.8s ease-in-out 0s;
	-moz-transition: opacity 0.8s ease-in-out 0s;
	-o-transition: opacity 0.8s ease-in-out 0s;
	-ms-transition: opacity 0.8s ease-in-out 0s;
	transition: opacity 0.8s ease-in-out 0s; */
}
.main-box {
	background: #FFFFFF;
    -webkit-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -moz-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -o-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -ms-box-shadow: 1px 1px 2px 0 #CCCCCC;
    box-shadow: 1px 1px 2px 0 #CCCCCC;
    margin-bottom: 16px;
    /* overflow: hidden; */
    -webikt-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.main-box h2 {
    font-size: 1.3em;
    line-height: 29px;
    margin: 0;
    padding: 0;
}
.main-box.no-header {
	padding-top: 20px;
}
.main-box .main-box-header {
    min-height: 50px;
    padding: 10px 20px;
}
.main-box .main-box-header.with-border {
	border-bottom: 1px solid #ecf0f1;
}
.main-box .main-box-body {
	padding: 0 20px 20px 20px;
}
@media (max-width: 991px) {
	#content-wrapper {
		margin-left: 0;
	}
}
@media (max-width: 767px) {
	#content-wrapper {
		padding: 10px 8px 0 8px;
	}
	.main-box {
		margin-bottom: 10px;
	}
}
@media (max-width: 420px) {
	#content-wrapper {
		padding: 5px 5px 0 5px;
	}
	.main-box {
		
	}
	.main-box h2 {
		margin-bottom: 5px;
	}
}


#footer-bar {
	background: #fff;
    bottom: 0;
    font-size: 0.8em;
    height: 36px;
    line-height: 36px;
    margin-left: -15px;
    margin-right: -15px;
    position: absolute;
    width: 100%;
}
#footer-copyright {
	text-align: center;
	margin: 0;
}
#footer-copyright a {
	
}
#footer-copyright a:hover {
	
}
@media (max-width: 767px) {
	#footer-bar {
		margin-left: -8px;
		margin-right: -8px;
	}
}
@media (max-width: 420px) {
	#footer-bar {
		margin-left: -5px;
		margin-right: -5px;
	}
}


/* OTHER */
.the-icons {
	font-size: 0.875em;
	line-height: 2;
}
.the-icons .glyphicon {
	width: 30px;
	text-align: center;
}
.tabs-wrapper .nav-tabs {
    margin-bottom: 15px;
}
.tabs-wrapper .tab-content {
	margin-bottom: 20px;
    padding: 0 10px;
}
.dropdown-example ul.dropdown-menu {
	display: block;
}
svg {
	width: 100% !important;
}

.breadcrumb {
	background: none;
	margin-bottom: 0;
}
.breadcrumb > li {
	color: #262626;
	margin-left: 8px;
}
.breadcrumb > li span {
	padding-left: 8px;
}
.breadcrumb > li a {
	color: #262626;
}
.breadcrumb > li + li:before {
	color: #262626;
	font-family: FontAwesome;
	content: "\f105";
}
.breadcrumb > .active {
    font-weight: 600;
    text-transform: none;
	color: #262626;
}
.breadcrumb > li:first-child {
	margin-left: 0;
}

/* FIXED ELEMENTS */
.fixed-header #header-navbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}
.fixed-header #page-wrapper {
	padding-top: 50px;
}
.fixed-footer #footer-bar {
    bottom: 0;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 999;
}
.fixed-footer #content-wrapper {
	padding-bottom: 36px
}
@media (min-width: 992px) {
	.fixed-leftmenu #nav-col {
		position: fixed;
		height: 100%;
	}
	.fixed-leftmenu.fixed-footer #nav-col {
		padding-bottom: 36px;
	}
	.fixed-leftmenu.fixed-header #nav-col {
		padding-bottom: 50px;
	}
	.fixed-leftmenu.fixed-header.fixed-footer #nav-col {
		padding-bottom: 86px;
	}
}
@media (max-width: 991px) {
	.fixed-header #nav-col {
		position: fixed;
		width: 100%;
	}
}



#powered-by{
    text-align: center;
    font-style: italic;
    font-size: .8em;
    margin-top: 50px;
}

span.description{
    font-style: italic;
    font-size: .7em;
    
}
div.description{
    font-style: italic;
    font-size: .9em;
    margin-bottom: 20px;
    padding-left: 15px;
    
}


#cj_overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #262626;
    opacity: 0.8;
    filter: alpha(opacity=80);
    z-index:9001;
}
#cj_loading h2{
 color:white;
 font-weight: 400;
 text-align: center;
}
#cj_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -28px 0 0 -25px;
}

.fixedscrolldiv{
    top: 90px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
}

.clickable{
    cursor: pointer;
}
.clickable:hover a {
    text-decoration: underline;
}

dl.contestantInfo dd{
    margin: 5px 5px 10px 10px;
}

#flickrTable th{
    min-width: 155px;
}
#entryFields dd{
    margin-left: 25px;
    margin-bottom: 5px;
}
#entryFields dt{
    float: none;
    width: 100%;
    text-align: left;
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
    padding: 0px !important;
}


.paginate_button.previous.disabled,
.paginate_button.next.disabled{
    display: none !important;
}
.noUi-connect{
    background-color: #3498db !important;
}