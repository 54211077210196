$color_1: #555;
$color_2: #ffffff;
$color_3: #6399cd;
$color_4: #8a1f11;
$color_5: #514721;
$color_6: #264409;
$color_7: #298dcd;
$color_8: #0066A4;
$background-color_1: transparent;
$background-color_2: #EFF4FA;
$border-color_1: #FBC2C4;
$border-color_2: #FFD324;
$border-color_3: #C6D880;


div.flash-error {
  padding: .8em;
  margin-bottom: 1em;
  border: 2px solid #ddd;
  background: #FBE3E4;
  color: $color_4;
  border-color: $border-color_1;

  a {
    color: $color_4;
  }
}

div.flash-notice {
  padding: .8em;
  margin-bottom: 1em;
  border: 2px solid #ddd;
  background: #FFF6BF;
  color: $color_5;
  border-color: $border-color_2;

  a {
    color: $color_5;
  }
}

div.flash-success {
  padding: .8em;
  margin-bottom: 1em;
  border: 2px solid #ddd;
  background: #E6EFC2;
  color: $color_6;
  border-color: $border-color_3;

  a {
    color: $color_6;
  }
}

div.form {
  .rememberMe {
    label {
      display: inline;
    }
  }
}

div.view {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #C9E0ED;
}

div.breadcrumbs {
  font-size: 0.9em;
  padding: 5px 20px;

  span {
    font-weight: bold;
  }
}

div.search-form {
  padding: 10px;
  margin: 10px 0;
  background: #eee;
}

.portlet-decoration {
  padding: 3px 8px;
  background: #B7D6E7;
  border-left: 5px solid #6FACCF;
}

.portlet-title {
  font-weight: bold;
  padding: 0;
  margin: 0;
  color: $color_7;
}

.portlet-content {
  font-size: 0.9em;
  margin: 0 0 15px 0;
  padding: 5px 8px;
  background: #EFFDFF;

  ul {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 2px 0 4px 0px;
  }
}

.operations {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 2px;

    a {
      font: bold 12px Arial;
      color: $color_8;
      display: block;
      padding: 2px 0 2px 8px;
      line-height: 15px;
      text-decoration: none;

      &:visited {
        color: $color_8;
      }

      &:hover {
        background: #80CFFF;
      }
    }
  }
}

body {
  color: #555555;
  background: #f0f0f0;
}

br {
  &.c {
    clear: both;
  }
}

hr {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  height: 0px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #f4f4f4;
}

fieldset {
  border: 1px solid #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-box-shadow: 1px 1px 0px #999;
  -webkit-box-shadow: 1px 1px 0px #999;
  box-shadow: 1px 1px 0px #999;
  padding: 20px 20px 0 20px;
  background: #fbfbfb;
  background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
  background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));
  margin-bottom: 15px;

  hr {
    border-bottom: 1px solid #fff;
  }

  legend {
    border: 1px solid #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-box-shadow: 1px 1px 0px #999;
    -webkit-box-shadow: 1px 1px 0px #999;
    box-shadow: 1px 1px 0px #999;
    background: #fbfbfb;
    background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
    background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));
    padding: 5px 20px;
    text-transform: uppercase;
  }

  p {
  }
}

h1 {
  color: #333333;
  clear: both;

  span {
    color: #1a1a1a;
  }
}

h2 {
  color: #333;
}

h5 {
  text-transform: uppercase;
  margin-bottom: 15px;
}

#container {
  min-width: 960px;
  max-width: 1600px;
}

#bgwrap {
  float: left;
  position: relative;
  width: 100%;
  background: url('assets/stripe.png') fixed 0 0 repeat-y;
}

#primary_left {
  clear: both;
  float: left;
  width: 230px;
  position: relative;

  #logo {
    clear: both;
    float: left;
    width: 230px;
    height: 155px;
    overflow: hidden;
    background: url('assets/logo_bg.png') 0 0 no-repeat;
    padding-top: 15px;
  }
}

#menu {
  ul {
    list-style-type: none;

    li {
      clear: both;
      float: left;
      background: url('assets/menu_spacer.png') 0 100% no-repeat;
      width: 220px;
      padding: 3px 0;

      &.nobg {
        background: none;
      }

      a {
        clear: both;
        float: left;
        text-decoration: none;
        color: #bbb;
        padding-left: 16px;
        height: 40px;

        img {
          clear: both;
          float: left;
          width: 24px;
          height: 24px;
          margin: 8px;
        }

        span {
          text-transform: uppercase;
          font-size: 13x;
          text-shadow: 1px 1px #000;
          float: left;
          width: 137px;
          padding: 10px 10px 7px 10px;
          font-family: Arial, Helvetica, sans-serif;
        }

        &:hover {
          span {
            color: #fff;
          }
        }
      }

      &.current {
        background: url('assets/menu_current.png') top right no-repeat;

        a {
          span {
            background: url('assets/menu_arrow.png') 100% 50% no-repeat;
            color: #fff;
            margin-left: 5px;
            text-shadow: #2c820a 1px 1px 0px;
            margin-left: 5px !important;
          }
        }

        li {
          a {
          }
        }
      }
    }

    ul {
      clear: both;
      float: left;
      width: 175px;
      display: inline;
      padding: 10px 0 10px 30px;
      margin-bottom: 3px;

      li {
        a {
          clear: both;
          float: left;
          width: 140px;
          padding: 3px;
          text-shadow: 1px 1px #000 !important;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

#menu ul ul li, #menu ul ul li a {
  background: none;
  height: auto !important;
}

#primary_right {
  float: right;
  width: 100%;
  margin-left: -255px;
  min-height: 1000px;

  .inner {
    clear: both;
    float: left;
    padding: 30px;
    margin-left: 230px;
    position: relative;
    min-width: 70%;
  }
}

.notification {
  width: auto;
  display: block;
  position: relative;
  padding: 10px 20px 20px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-box-shadow: 1px 1px 0px #999;
  -webkit-box-shadow: 1px 1px 0px #999;
  box-shadow: 1px 1px 0px #999;
  margin-bottom: 30px;

  span {
    background: url('assets/close.png') no-repeat right top;
    display: block;
    width: 19px;
    height: 19px;
    position: absolute;
    top: -9px;
    right: -8px;
  }

  .text {
    overflow: hidden;
  }

  p {
    width: auto;
    color: #555;
    line-height: 10px;
    text-align: justify;
    float: left;
    margin-right: 15px;
    *margin-top: 15px;
    margin-bottom: 0 !important;
    padding-top: 12px;
  }

  strong {
  }
}

.autoWidth {
  width: auto;

  p {
    width: auto;
    float: left;
  }
}

.success {
  border-top: 1px solid #edf7d0;
  border-bottom: 1px solid #b7e789;
  background: #dff3a8;
  background: -moz-linear-gradient(top, #dff3a8, #c4fb92);
  background: -webkit-gradient(linear, left top, left bottom, from(#dff3a8), to(#c4fb92));

  &:before {
    content: url(assets/icons/notifications/success.png);
    float: left;
    margin: 10px 15px 0px 15px;
  }

  strong {
    color: #61b316;
    margin-right: 15px;
  }
}

.warning {
  border-top: 1px solid #fefbcd;
  border-bottom: 1px solid #e6e837;
  background: #feffb1;
  background: -moz-linear-gradient(top, #feffb1, #f0f17f);
  background: -webkit-gradient(linear, left top, left bottom, from(#feffb1), to(#f0f17f));

  &:before {
    content: url(assets/icons/notifications/warning.png);
    float: left;
    margin: 2px 15px 0px 25px;
  }

  strong {
    color: #e5ac00;
    margin-right: 15px;
  }
}

.tip {
  border-top: 1px solid #fbe4ae;
  border-bottom: 1px solid #d9a87d;
  background: #f9d9a1;
  background: -moz-linear-gradient(top, #f9d9a1, #eabc7a);
  background: -webkit-gradient(linear, left top, left bottom, from(#f9d9a1), to(#eabc7a));

  &:before {
    content: url(assets/icons/notifications/tip.png);
    float: left;
    margin: 7px 15px 0px 15px;
  }

  strong {
    color: #b26b17;
    margin-right: 15px;
  }
}

.error {
  border-top: 1px solid #f7d0d0;
  border-bottom: 1px solid #c87676;
  background: #f3c7c7;
  background: -moz-linear-gradient(top, #f3c7c7, #eea2a2);
  background: -webkit-gradient(linear, left top, left bottom, from(#f3c7c7), to(#eea2a2));

  &:before {
    content: url(assets/icons/notifications/error.png);
    float: left;
    margin: 7px 15px 0px 15px;
  }

  strong {
    color: #b31616;
    margin-right: 15px;
  }
}

.secure {
  border-top: 1px solid #efe0fe;
  border-bottom: 1px solid #d3bee9;
  background: #e5cefe;
  background: -moz-linear-gradient(top, #e5cefe, #e4bef9);
  background: -webkit-gradient(linear, left top, left bottom, from(#e5cefe), to(#e4bef9));

  &:before {
    content: url(assets/icons/notifications/secure.png);
    float: left;
    margin: 5px 15px 0px 15px;
  }

  strong {
    color: #6417b2;
    margin-right: 15px;
  }
}

.info {
  border-top: 1px solid #f3fbff;
  border-bottom: 1px solid #bedae9;
  background: #e0f4ff;
  background: -moz-linear-gradient(top, #e0f4ff, #d4e6f0);
  background: -webkit-gradient(linear, left top, left bottom, from(#e0f4ff), to(#d4e6f0));

  &:before {
    content: url(assets/icons/notifications/info.png);
    float: left;
    margin: 5px 15px 0px 21px;
  }

  strong {
    color: #177fb2;
    margin-right: 15px;
  }
}

.message {
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #d7d7d7;
  background: #f0f0f0;
  background: -moz-linear-gradient(top, #f0f0f0, #e1e1e1);
  background: -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#e1e1e1));

  &:before {
    content: url(assets/icons/notifications/message.png);
    float: left;
    margin: 12px 15px 0px 15px;
  }

  strong {
    color: #323232;
    margin-right: 15px;
  }
}

.download {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #eeeeee;
  background: #f7f7f7;
  background: -moz-linear-gradient(top, #f7f7f7, #f0f0f0);
  background: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f0f0f0));

  &:before {
    content: url(assets/icons/notifications/download.png);
    float: left;
    margin: 3px 15px 0px 18px;
  }

  strong {
    color: #037cda;
    margin-right: 15px;
  }
}

.purchase {
  border-top: 1px solid #d1f7f8;
  border-bottom: 1px solid #8eabb1;
  background: #c4e4e4;
  background: -moz-linear-gradient(top, #c4e4e4, #97b8bf);
  background: -webkit-gradient(linear, left top, left bottom, from(#c4e4e4), to(#97b8bf));

  &:before {
    content: url(assets/icons/notifications/purchase.png);
    float: left;
    margin: 6px 15px 0px 15px;
  }

  strong {
    color: #426065;
    margin-right: 15px;
  }
}

.print {
  border-top: 1px solid #dde9f3;
  border-bottom: 1px solid #8fa6b2;
  background: #cfdde8;
  background: -moz-linear-gradient(top, #cfdde8, #9eb3bd);
  background: -webkit-gradient(linear, left top, left bottom, from(#cfdde8), to(#9eb3bd));

  &:before {
    content: url(assets/icons/notifications/print.png);
    float: left;
    margin: 6px 15px 0px 15px;
  }

  strong {
    color: #3f4c6b;
    margin-right: 15px;
  }
}

ul {
  &.dash {
    list-style-type: none;

    li {
      float: left;
      margin: 0 15px 15px 0;
      width: 100px;
      height: 100px;
      position: relative;
      z-index: 80;
      border: 1px solid #fff;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-box-shadow: 1px 1px 0px #999;
      -webkit-box-shadow: 1px 1px 0px #999;
      box-shadow: 1px 1px 0px #999;
      background: #fbfbfb;
      background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
      background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));

      &:hover {
        cursor: hand;
      }

      a {
        text-decoration: none;
        clear: both;
        float: left;
        width: 100px;
        height: 100px;

        img {
          clear: both;
          float: left;
          display: inline;
          margin: 10px 0 5px 18px;
        }

        span {
          clear: both;
          float: left;
          width: 100%;
          text-align: center;
          color: #666;
        }
      }

      .bubble {
        position: absolute;
        padding: 1px 6px 1px 3px;
        line-height: 12px;
        font-weight: bold;
        letter-spacing: -1px;
        top: -7px;
        right: -8px;
        color: #fff;
        z-index: 90;
        border: 1px solid #b20606;
        border-radius: 7px;
        -moz-border-radius: 7px;
        -webkit-border-radius: 7px;
        background: #ff2e2e;
        background: -moz-linear-gradient(top, #ff2e2e, #c80606);
        background: -webkit-gradient(linear, left top, left bottom, from(#ff2e2e), to(#c80606));
      }
    }
  }

  &.paginator {
    list-style-type: none;
    float: right;

    li {
      float: left;

      a {
        padding: 4px 6px;
        margin: 0 5px 0 0;
        text-decoration: none;
        color: #666;
        border: 1px solid #fff;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-box-shadow: 1px 1px 0px #999;
        -webkit-box-shadow: 1px 1px 0px #999;
        box-shadow: 1px 1px 0px #999;
        background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
        background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));

        &.current {
          border: 1px solid #1a1a1a;
          color: #ccc;
          background: url('assets/blue/button_repeater.png') 0 0 repeat-x;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-box-shadow: 1px 1px 0px #999;
          -webkit-box-shadow: 1px 1px 0px #999;
          box-shadow: 1px 1px 0px #999;
        }
      }
    }
  }
}

.selected {
  border: 2px solid #bb1ce6 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.clearboth {
  clear: both;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
  border-left: 0px;
  border-right: 0px;
}


table {
  width: 100%;
  border-spacing: 0;
  vertical-align: middle;

  tr.highlight, tr.highlight td, tr.highlight td a {
    background: $primary !important;
    color: $light !important;
  }

  &.fancy {
    border-top: 2px solid #333;
    margin-bottom: 20px;
    border-bottom: 1px solid #f4f4f4;

    th {
      color: #666;
      text-transform: uppercase;
      padding: 10px 20px;
      vertical-align: middle;
      background: #f5f5f5;
      font-family: Arial, Helvetica, sans-serif;
      border-top: 1px solid #f4f4f4;
    }

    td {
      padding: 10px 20px;
      border-bottom: 1px solid #aaa;
      border-top: 1px solid #f4f4f4;
      text-align: center;
    }
  }

  &.normal {
    border: 1px solid #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-box-shadow: 1px 1px 0px #999;
    -webkit-box-shadow: 1px 1px 0px #999;
    box-shadow: 1px 1px 0px #999;

    td {
      padding: 5px 15px;
    }

    thead {
      th {
        background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
        background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));
        text-transform: uppercase;
        font-weight: normal;
        border-bottom: 1px solid #ccc;
        text-shadow: -1px -1px #fff;
        padding: 5px 15px;
        text-align: left;

        &:hover {
          cursor: pointer;
        }
      }
    }

    tbody {
      border-top: 1px solid #fff;
      background: #f4f4f4;

      tr {
        &.odd {
          td {
            background: #fafafa;
          }
        }
      }

      td {
        vertical-align: middle;
      }
    }
  }

  &.fullwidth {
    width: 100%;
  }

  tfoot {
    background: #fafafa;

    td {
      border-top: 1px solid #fff;
      background: -moz-linear-gradient(top, #fbfbfb, #f5f5f5);
      background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));
    }
  }

  &.permissions {
    label {
      padding-right: 20px;
    }
  }
}

//input, select, textarea {
//	padding: 6px 10px;
//	background: #dfdfdf;
//	color: #444;
//	border-width: 1px;
//	border-style: solid;
//	border-color: #d9d9d9 #eaeaea white;
//	border-radius: 5px;
//	-moz-border-radius: 5px;
//	-webkit-border-radius: 5px;
//		margin-right: 15px;
//}
//select {
//	height: 32px;
//	option {
//		padding: 6px 10px;
//	}
//}
//input.sf, textarea.sf {
//	width: 180px;
//}
//input.mf, textarea.mf {
//	width: 270px;
//}
//input.lf, textarea.lf {
//	width: 360px;
//}
//input[type="radio"], input[type="checkbox"] {
//	margin-right: 5px;
//	}
//.validate_success {
//	color: #739F1D;
//}
//.validate_error {
//	color: red;
//}
//input[type="checkbox"] {
//	& + label {
//		height: 16px;
//	}
//}
//p {
//	label {
//		display: inline-block;
//		width: 150px;
//				vertical-align: middle;
//	}
//	&.competitionList {
//		border: 1px solid #666;
//		padding: 5px;
//		float: left;
//	}
//}
//label {
//	&.fix {
//		position: relative;
//		top: -7px;
//	}
//}
//.field_desc {
//	color: #777;
//	font-style: italic;
//}
//a {
//	&.table_icon {
//		float: left;
//		margin-right: 5px;
//		padding: 5px 5px 0px 5px;
//		border: 1px solid #fff;
//		border-radius: 5px;
//		-moz-border-radius: 5px;
//		-webkit-border-radius: 5px;
//		-moz-box-shadow: 1px 1px 0px #999;
//		-webkit-box-shadow: 1px 1px 0px #999;
//		box-shadow: 1px 1px 0px #999;
//		background: -moz-linear-gradient(top,#fbfbfb,#f5f5f5);
//		background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#f5f5f5));
//	}
//}
//
//#easyTooltip {
//	position: relative;
//	padding: 10px 15px 10px 15px;
//	color: #FFF;
//		font-family: Arial, Helvetica, sans-serif;
//	font-weight: bold;
//	min-width: 60px;
//	text-shadow: 0 1px 1px #5e026f;
//	line-height: 16px;
//	z-index: 2000;
//	border: 1px solid #bb1ce6;
//	border-bottom: 1px solid #b015d8;
//	-moz-border-radius: 10px;
//	-webkit-border-radius: 10px;
//	border-radius: 10px;
//	background: #c923f4;
//	background: -moz-linear-gradient(top, #ff9efe, #f526f4 2%, #c923f4);
//	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffd683), color-stop(.01, #f526f4), to(#c923f4));
//	&:before {
//		content: "\00a0";
//		display: block;
//		position: absolute;
//		bottom: -16px;
//		left: 46px;
//		width: 0;
//		height: 0;
//		border: 8px solid transparent;
//		border-top-color: #b25708;
//	}
//	&:after {
//		content: "\00a0";
//		display: block;
//		position: absolute;
//		bottom: -14px;
//		left: 47px;
//		width: 0;
//		height: 0;
//		border: 7px solid transparent;
//		border-top-color: #c923f4;
//	}
//}
//div {
//	&.attributes {
//		span {
//			&.name {
//				font-weight: bold;
//			}
//		}
//	}
//}
